import {
  Box,
  ButtonLink,
  Card,
  Flex,
  Heading,
  Text,
} from '@applyboard/crystal-ui'
import { Application } from '../../clients'

type AppCardProps = {
  app: Application
  available?: boolean
}

export function AppCard({ app, available }: AppCardProps) {
  return (
    <Card height="fill">
      <Card.Header>
        <Flex gap={16}>
          <Box>
            <Heading level={3}>{app.title}</Heading>
            <Text contrast="mid" intent="secondary">
              {app.description}
            </Text>
          </Box>
          <Flex>
            <img src={app.icon} alt={`${app.title} logo`} />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Footer>
        <Flex justify="end" p={2} gap={2}>
          <ButtonLink
            emphasis="transparent"
            intent="secondary"
            size="sm"
            href={app.learnMoreLink} /* TODO: Replace with the actual link */
          >
            Learn More
          </ButtonLink>
          {available ? (
            <ButtonLink
              emphasis="outlined"
              intent="primary"
              size="sm"
              href={app.appLink} /* TODO: Replace with the actual link */
            >
              Launch
            </ButtonLink>
          ) : (
            // TODO: enable once product has decided what "Request" will link to.
            // <ButtonLink
            //   emphasis="outlined"
            //   intent="primary"
            //   size="sm"
            //   href="#" /* TODO: Replace with the actual link */
            // >
            //   Request
            // </ButtonLink>
            <></>
          )}
        </Flex>
      </Card.Footer>
    </Card>
  )
}
