import { useMemo } from 'react'
import { UserSession } from '../../auth'
import { useConfig } from '../config'
import { useLoginRedirect } from './useLoginRedirect'

export function useLogout() {
  const config = useConfig()
  const { handleLoginRedirect } = useLoginRedirect()

  const handleLogout = useMemo(() => {
    return () => {
      const userSession = new UserSession(config)
      userSession.logout()

      handleLoginRedirect()
    }
  }, [config, handleLoginRedirect])

  return { handleLogout }
}
