import { UserType } from '../../clients'
import { getDisplayLabel } from '../../clients/UserTypeToDisplayLabel'
import { USER_TYPE_HIERARCHY } from '../../utils/auth/UserTypeHierarchy'
import { PlatformUserTypeOption } from './ProfileForm'

export function userTypeToPlatformUserTypeOptions(
  userType: UserType
): PlatformUserTypeOption[] {
  return USER_TYPE_HIERARCHY[userType].map((userType) => {
    return {
      label: getDisplayLabel(userType),
      value: userType,
    }
  })
}
