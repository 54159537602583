// taken from @applyboard/crystal-ui

function rgba(alpha: number) {
  return `rgba(54, 59, 81, ${alpha})` // all elevation colors are based on gray05
}

export const elevations = {
  z1: `0px 2px 8px ${rgba(0.18)}, 0px 0px 2px ${rgba(0.14)}, 0px 2px 4px ${rgba(
    0.12
  )}`,
  z2: `0px 2px 9px ${rgba(0.19)}, 0px 1px 6px ${rgba(
    0.14
  )}, 0px 4px 10px ${rgba(0.12)}`,
  z3: `0px 2px 10px ${rgba(0.14)}, 0px 4px 16px ${rgba(
    0.12
  )}, 0px 6px 18px ${rgba(0.18)}`,
}

export type ElevationToken = keyof typeof elevations
