import { useQuery } from 'react-query'
import { ApplicationsClient } from '../../clients'

export function useApplications() {
  const { isLoading, data, isError } = useQuery(['applications'], async () => {
    return await ApplicationsClient.getAllApplications()
  })

  return {
    allApplicationsData: data,
    isApplicationsLoading: isLoading,
    isApplicationsError: isError,
  }
}
