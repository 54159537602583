import { ButtonLink, Drawer, Flex } from '@applyboard/crystal-ui'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'

import { useScreenResize } from '../../../hooks'
import { NavBarItems } from './Sidebar'

export function MobileSidebar(props: {
  open: boolean
  onOpenChange: (open: boolean) => void
  navbarItems: Array<NavBarItems>
}) {
  useScreenResize(() => {
    if (window.innerWidth >= 600) {
      props.onOpenChange(false)
    }
  })

  return (
    <Flex hideAbove="sm" as="aside">
      <Global
        styles={{
          'aside[class*="Drawer"]': {
            borderRadius: 8,
            minHeight: '70vh',
            bottom: 0,
          },
          'aside[class*="Drawer"] > [class*="DrawerBody"]': {
            display: 'flex',
            borderRadius: 8,
          },
        }}
      />
      <Drawer
        open={props.open}
        onOpenChange={props.onOpenChange}
        placement="bottom"
        aria-label="Menu"
      >
        <MobileSidebarContainer>
          <Flex direction="column" grow={1} p={2} justify="between">
            <Flex direction="column">
              {props.navbarItems.map((item) => (
                <ButtonLink
                  key={item.id}
                  href={item.href}
                  leadIcon={item.icon}
                  width="fill"
                  intent={item.selected ? 'primary' : 'secondary'}
                  emphasis={item.selected ? 'highlighted' : 'transparent'}
                  aria-label={item.label}
                >
                  {props.open ? item.label : undefined}
                </ButtonLink>
              ))}
            </Flex>
          </Flex>
        </MobileSidebarContainer>
      </Drawer>
    </Flex>
  )
}

const MobileSidebarContainer = styled.div(() => ({
  display: 'flex',
  flexGrow: 1,
  'a > div': {
    justifyContent: 'flex-start',
  },
}))
