import config from './config.json'

export type Config = {
  hostedZoneName: string
}

export function getConfig(): Config {
  return {
    hostedZoneName: config.hostedZoneName,
  }
}
