import { useMutation } from 'react-query'
import { ConfirmPasswordParams } from '../clients'
import { CognitoClient } from '../clients/CognitoClient'
import { useConfig } from './config'

export function useSetPassword() {
  const config = useConfig()
  const client = new CognitoClient(config)

  const { isLoading, mutate, error, isError, isSuccess } = useMutation(
    async (params: ConfirmPasswordParams) => {
      await client.confirmPassword(params)
    }
  )

  return {
    isSetPasswordLoading: isLoading,
    isSetPasswordSuccess: isSuccess,
    handleSetPassword: mutate,
    isSetPasswordError: isError,
    setPasswordError: error,
  }
}
