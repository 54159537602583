import { Flex } from '@applyboard/crystal-ui'
import { AppDashboard } from '../../components/AppDashboard'
import { HelpBanner } from '../../components/HelpBanner'
import { NavBars } from '../../components/NavBars'

export function DashboardPage(): JSX.Element {
  return (
    <NavBars selectedMenuItem="Home" addFooter>
      <Flex direction="column" gap={4} p={8} grow={1} justify="between">
        <AppDashboard />
        <HelpBanner />
      </Flex>
    </NavBars>
  )
}
