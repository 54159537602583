import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { ApiClient, Config } from '../../clients'
import { LoadingPage } from '../../pages/LoadingPage'

const fetchConfig = async (): Promise<Config> => {
  return await ApiClient.getConfiguration()
}

const ConfigContext = createContext<Config | undefined>(undefined)

interface ConfigProviderProps {
  children: React.ReactNode
}

const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const {
    data: config,
    isLoading,
    isError,
    error,
  } = useQuery<Config>('config', fetchConfig)

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    console.log(error)
    window.location.href = '/error'
    return <LoadingPage />
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

const useConfig = (): Config => {
  const context = useContext(ConfigContext)

  // allows us to guarantee that config is always defined when useConfig is called
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}

export { ConfigProvider, useConfig }
