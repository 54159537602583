/**
 * Validates if an email format is valid. Returns false if not a valid email format.
 * @param email Email to validate
 */
export function validateEmailFormat(email: string): boolean {
  return (
    email.match(
      /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
    ) !== null
  )
}
