import {
  HomeOutlineIcon,
  PersonAddOutlineIcon,
  PersonOutlineIcon,
  SvgIconProps,
} from '@applyboard/ui-icons'
import { Action, ProfileData } from '../../../clients'
import { hasPermission } from '../../../utils/auth'
import { DesktopSidebar } from './DesktopSidebar'
import { MobileSidebar } from './MobileSidebar'

const MENU_LABELS = [
  'Home',
  'Search',
  'All Profiles',
  'Create Profile',
] as const
export type MenuItemLabel = (typeof MENU_LABELS)[number]

type SidebarProps = {
  isDesktopSidebarOpen: boolean
  isMobileSidebarOpen: boolean
  onMobileSidebarOpenChange: (open: boolean) => void
  profile: ProfileData
  selectedMenuItem: MenuItemLabel
}

export type NavBarItems = {
  id: string
  href: string
  label: MenuItemLabel
  icon: React.ComponentType<Omit<SvgIconProps, 'children'>>
  selected?: boolean
}

export function Sidebar(props: SidebarProps) {
  const navBarPrimaryItems: Array<NavBarItems> = [
    {
      id: crypto.randomUUID(),
      href: '/',
      label: 'Home',
      icon: HomeOutlineIcon,
      selected: props.selectedMenuItem === 'Home',
    },
    {
      id: crypto.randomUUID(),
      href: '/profiles',
      label: 'All Profiles',
      icon: PersonOutlineIcon,
      selected: props.selectedMenuItem === 'All Profiles',
    },
  ]

  const createProfileItem: NavBarItems = {
    id: crypto.randomUUID(),
    href: '/profiles/add',
    label: 'Create Profile',
    icon: PersonAddOutlineIcon,
    selected: props.selectedMenuItem === 'Create Profile',
  }

  const navBarSecondaryItems: Array<NavBarItems> = hasPermission(
    props.profile.userType,
    Action.CREATE
  )
    ? [createProfileItem]
    : []

  return (
    <>
      <MobileSidebar
        open={props.isMobileSidebarOpen}
        onOpenChange={props.onMobileSidebarOpenChange}
        navbarItems={navBarPrimaryItems}
      />
      <DesktopSidebar
        open={props.isDesktopSidebarOpen}
        primaryNavBarItems={navBarPrimaryItems}
        secondaryNavBarItems={navBarSecondaryItems}
      />
    </>
  )
}
