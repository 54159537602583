import {
  Box,
  Button,
  Flex,
  Heading,
  HelpText,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useSetPassword } from '../../hooks/useSetPassword'
import { ErrorBlock } from './ErrorBlock'
import { SuccessBlock } from './SuccessBlock'

export type SetPasswordFormFields = {
  newPassword: string
  confirmNewPassword: string
}

export type SetPasswordFormProps = {
  username: string
  temporaryPassword: string
}

const { Form, Field, useFieldValues } = createForm<SetPasswordFormFields>()

export function SetPasswordForm({
  username,
  temporaryPassword,
}: SetPasswordFormProps): JSX.Element {
  const {
    isSetPasswordLoading,
    handleSetPassword,
    isSetPasswordError,
    isSetPasswordSuccess,
  } = useSetPassword()

  return (
    <Flex gap={4} align="stretch" direction="column" justify="center">
      <Box pb={8}>
        <img alt="ApplyBoard logo" src="/applyboard-logo.svg" width={200} />
      </Box>
      {isSetPasswordSuccess ? (
        <SuccessBlock />
      ) : isSetPasswordError ? (
        <ErrorBlock />
      ) : (
        <>
          <Flex direction="column" pb={1}>
            <Heading level={2} variant="titleL">
              Create new password
            </Heading>
            <Box pt={2}>
              <Text intent="primary" contrast="low">
                Enter your new password and confirm it
              </Text>
              <Text intent="primary" contrast="low">
                to complete your account setup.
              </Text>
            </Box>
          </Flex>
          <Flex gap={{ xs: 6, sm: 4 }} direction="column">
            <FormBox>
              <Form
                onSubmit={async (data) => {
                  handleSetPassword({
                    temporaryPassword: temporaryPassword,
                    username: username,
                    newPassword: data.newPassword,
                  })
                }}
                defaultValues={{
                  newPassword: '',
                  confirmNewPassword: '',
                }}
              >
                <Box pb={8}>
                  <Field
                    as={TextInput}
                    label="Password"
                    name="newPassword"
                    required="Password is required."
                    type="password"
                  />
                </Box>
                <Box pb={8}>
                  <Field
                    as={TextInput}
                    label="Confirm Password"
                    name="confirmNewPassword"
                    type="password"
                    required="Please confirm your password."
                    validate={(confirmNewPassword, { values }) => {
                      if (
                        values.newPassword &&
                        confirmNewPassword !== values.newPassword
                      ) {
                        return 'Password confirmation does not match.'
                      }
                      return true
                    }}
                  />
                </Box>
                {isSetPasswordError && (
                  <Box pb={8}>
                    <HelpText intent="negative">
                      Error setting password.
                    </HelpText>
                  </Box>
                )}
                <Box>
                  <SubmitButton loading={isSetPasswordLoading} />
                </Box>
              </Form>
            </FormBox>
          </Flex>
        </>
      )}
    </Flex>
  )
}

const FormBox = styled.div(() => ({
  width: '100%',
  minWidth: 300,
  maxWidth: 600,
}))

const SubmitButton = ({ loading }: { loading: boolean }) => {
  const { newPassword, confirmNewPassword } = useFieldValues([
    'newPassword',
    'confirmNewPassword',
  ])

  return (
    <Button
      intent="primary"
      width="fill"
      type="submit"
      disabled={!newPassword.length || newPassword !== confirmNewPassword}
      loading={loading}
    >
      Set password
    </Button>
  )
}
