import { useMutation } from 'react-query'
import { ApiClient, CreateProfileProps } from '../../clients'
import { useConfig } from '../config'

export function useCreateProfile() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, mutate, error, isError } = useMutation<
    void,
    Error,
    CreateProfileProps
  >(async (props: CreateProfileProps) => {
    return await apiClient.createProfile(props)
  })

  return {
    isCreatingProfile: isLoading,
    isCreateProfileError: isError,
    createProfileError: error,
    createProfile: mutate,
  }
}
