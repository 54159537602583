import { useQuery } from 'react-query'
import { ApiClient } from '../../clients'
import { useConfig } from '../config'

export function useAllProfiles() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, isError, refetch } = useQuery(
    ['profiles'],
    async () => {
      return await apiClient.getAllProfiles()
    }
  )

  return {
    allProfileData: data,
    isProfilesLoading: isLoading,
    isProfilesError: isError,
    refetchAllProfileData: refetch,
  }
}
