import { Action, UserType } from '../../clients'

const ACTIONS_FOR_USER_TYPES: Record<Action, UserType[]> = {
  CREATE: [UserType.admin, UserType.owner],
  DELETE: [UserType.owner, UserType.admin],
  READ: [UserType.owner, UserType.admin, UserType.staff],
  UPDATE: [UserType.owner, UserType.admin, UserType.staff],
}

/** Checks if a user can perform a given action */
export function hasPermission(userType: UserType, action: Action): boolean {
  return ACTIONS_FOR_USER_TYPES[action].includes(userType)
}
