import { ButtonLink, Flex } from '@applyboard/crystal-ui'
import { PersonAddOutlineIcon } from '@applyboard/ui-icons'
import { PageHeading } from '../PageHeading'

type UserManagementHeadingProps = {
  showAddUserButton: boolean
}

export function UserManagementHeading(
  props: UserManagementHeadingProps
): JSX.Element {
  const { showAddUserButton } = props

  return (
    <Flex justify="between">
      <PageHeading
        title="User Management"
        imageSrc="/user-management-icon.svg"
        imageAlt="user management icon"
      />
      <Flex>
        {showAddUserButton && (
          <ButtonLink
            href="/profiles/add"
            leadIcon={PersonAddOutlineIcon}
            intent="primary"
            width="fill"
          >
            Add User
          </ButtonLink>
        )}
      </Flex>
    </Flex>
  )
}
