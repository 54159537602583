import { useMutation } from 'react-query'
import { ApiClient } from '../../clients'
import { useConfig } from '../config'

type DeleteProfileProps = {
  id: string
}

export function useDeleteProfile() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, mutate } = useMutation(
    async ({ id }: DeleteProfileProps) => {
      return await apiClient.deleteProfile(id)
    }
  )

  return {
    isDeletingProfile: isLoading,
    deleteProfile: mutate,
  }
}
