import { SemPayload } from '@backend/platform-api/types'
import jwt_decode from 'jwt-decode'
import { AuthClient, Config } from '../clients'
import { InvalidRefreshTokenError } from '../errors'
import { InvalidAccessTokenError } from '../errors/InvalidAccessTokenError'
import { AuthStorage } from '../utils'

export class UserSession {
  constructor(
    private readonly config: Config,
    private readonly authClient = new AuthClient()
  ) {}

  async authenticate(code: string): Promise<void> {
    const authEndpoint = `${this.config.cognitoEndpoint}/oauth2/token`
    const response = await AuthClient.authenticateCode({
      authCode: code,
      clientId: this.config.userPoolClientId,
      redirectUri: this.config.redirectUri,
      authEndpoint,
      grantType: 'authorization_code',
    })

    AuthStorage.setAuthCredentials({
      refreshToken: response.refresh_token,
      idToken: response.id_token,
      accessToken: response.access_token,
    })
  }

  logout(): void {
    AuthStorage.removeAuthCredentials()
  }

  async getAuthToken(): Promise<string> {
    if (!this.isActiveSession()) {
      await this.refreshSession()
    }

    const idToken = AuthStorage.getIdToken()
    if (idToken === undefined) {
      throw new InvalidAccessTokenError()
    }

    return idToken
  }

  private async refreshSession() {
    const refreshToken = AuthStorage.getRefreshToken()

    if (refreshToken === undefined) {
      this.logout()
      throw new InvalidRefreshTokenError()
    }

    const authEndpoint = `${this.config.cognitoEndpoint}/oauth2/token`

    const response = await this.authClient.refresh({
      refreshToken: refreshToken,
      clientId: this.config.userPoolClientId,
      authEndpoint,
      grantType: 'refresh_token',
    })

    AuthStorage.setAuthCredentials({
      idToken: response.id_token,
      accessToken: response.access_token,
    })
  }

  getAuthTokenPayload(): SemPayload {
    const accessToken = AuthStorage.getIdToken()

    if (!accessToken) {
      throw new InvalidAccessTokenError()
    }

    try {
      return jwt_decode(accessToken) as SemPayload
    } catch (err) {
      console.warn('no access token found')
      throw new InvalidAccessTokenError()
    }
  }

  isActiveSession(): boolean {
    try {
      const payload = this.getAuthTokenPayload()
      if (!payload?.exp) {
        return false
      }

      const expiryInMilliseconds = payload.exp * 1000
      const now = Date.now()
      const hasNotYetExpired = now < expiryInMilliseconds

      return hasNotYetExpired
    } catch (err) {
      if (
        err instanceof InvalidAccessTokenError ||
        err instanceof InvalidRefreshTokenError
      ) {
        return false
      }

      throw err
    }
  }
}
