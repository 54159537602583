import { LocalStorage } from './LocalStorage'

enum StorageKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  ID_TOKEN = 'idToken',
}

export type AuthCredentials = {
  accessToken: string
  idToken: string
  refreshToken?: string
}

/**
 * This class is responsible for facilitating get and set requests of
 * credentials to local storage.
 */
export class AuthStorage {
  static setAuthCredentials({
    accessToken,
    idToken,
    refreshToken,
  }: AuthCredentials): void {
    this.setAccessToken(accessToken)
    this.setIdToken(idToken)

    if (refreshToken) {
      this.setRefreshToken(refreshToken)
    }
  }

  static removeAuthCredentials(): void {
    LocalStorage.removeItem(StorageKeys.REFRESH_TOKEN)
    LocalStorage.removeItem(StorageKeys.ACCESS_TOKEN)
    LocalStorage.removeItem(StorageKeys.ID_TOKEN)
  }

  static setIdToken(newToken: string): void {
    this.setToken(StorageKeys.ID_TOKEN, newToken)
  }

  static setAccessToken(newToken: string): void {
    this.setToken(StorageKeys.ACCESS_TOKEN, newToken)
  }

  static setRefreshToken(newToken: string): void {
    this.setToken(StorageKeys.REFRESH_TOKEN, newToken)
  }

  static getIdToken(): string | undefined {
    return this.getToken(StorageKeys.ID_TOKEN)
  }

  static getAccessToken(): string | undefined {
    return this.getToken(StorageKeys.ACCESS_TOKEN)
  }

  static getRefreshToken(): string | undefined {
    return this.getToken(StorageKeys.REFRESH_TOKEN)
  }

  private static setToken(key: StorageKeys, value: string): void {
    try {
      LocalStorage.setItem(key, value)
    } catch (err) {
      return undefined
    }
  }

  private static getToken(key: StorageKeys): string | undefined {
    try {
      const item = LocalStorage.getItem(key)
      return item
    } catch (err) {
      return undefined
    }
  }
}
