import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import {
  Config,
  ConfirmPasswordParams,
  IsUserForceChangePasswordParams,
} from './types'

export class CognitoClient {
  private readonly userPool: CognitoUserPool

  constructor(config: Config) {
    this.userPool = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolClientId,
    })
  }
  async confirmPassword({
    username,
    temporaryPassword,
    newPassword,
  }: ConfirmPasswordParams): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    })

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: temporaryPassword,
    })

    return await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: reject, //  we should never get to this state
        onFailure: (err) => {
          reject(err)
        },
        newPasswordRequired: () => {
          cognitoUser.completeNewPasswordChallenge(newPassword, null, {
            onSuccess: () => resolve(),
            onFailure: (err) => reject(err),
          })
        },
      })
    })
  }

  /**
   * Checks to see if a user is still in the "Force Change Password" state.
   *
   * It accomplishes this by attempting to make an authentication call to Cognito with the
   * username and the temporary password. If the user is still in the "Force Change Password" state,
   * they will hit the `newPasswordRequired` flow, otherwise it will fail with `NotAuthorizedException` meaning
   * the password is wrong, indicating they have already set their password.
   */
  async isUserForceChangePassword({
    username,
    temporaryPassword,
  }: IsUserForceChangePasswordParams): Promise<boolean> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    })

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: temporaryPassword,
    })

    return await new Promise<boolean>((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: () => {
          resolve(false) // indicates the user has used their "temporaryCode" as their password
        },
        onFailure: (err) => {
          if (err.code === 'NotAuthorizedException') {
            resolve(false) // indicates the user has already set their password
          }
          reject(err)
        },
        newPasswordRequired: () => {
          resolve(true) // confirmation that the user is still in "force change password" state
        },
      })
    })
  }
}
