import { Flex } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { PropsWithChildren } from 'react'

/**
 * Page layout component used for
 * Login and Confirm Password pages.
 *
 * @param props.children The form to be used.
 */
export function OnboardContainer(props: PropsWithChildren): JSX.Element {
  return (
    <Flex align="stretch" justify="center">
      <FormContainer>
        <Flex align="center" justify="center" grow={1} shrink={1} basis="50%">
          {props.children}
        </Flex>
      </FormContainer>
      <ImagePanel />
    </Flex>
  )
}

const FormContainer = styled.div({
  height: '100vh',
  display: 'flex',
  flex: '1 1 50%',
})

const ImagePanel = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  flexDirection: 'row',
  flex: '1 1 50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  backgroundImage: 'url("/login-illustration-wide.png")',
  '@media (max-width: 700px)': {
    display: 'none',
  },
})
