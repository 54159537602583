import { Flex, Heading, Box, ButtonLink, Text } from '@applyboard/crystal-ui'

export function ErrorBlock(): JSX.Element {
  return (
    <>
      <Flex direction="column" pb={8}>
        <Heading level={2} variant="titleL" intent="negative">
          Error
        </Heading>
        <Box pt={4} pb={8}>
          <Text intent="primary" contrast="low">
            There was an error setting up your account.
          </Text>
        </Box>
        <Flex gap={{ xs: 6, sm: 4 }} direction="column">
          <ButtonLink href={'/'} intent="primary">
            Return to home
          </ButtonLink>
        </Flex>
      </Flex>
    </>
  )
}
