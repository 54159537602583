import { Flex, Text } from '@applyboard/crystal-ui'

export function NotFoundPage() {
  return (
    <Flex align="center" justify="center">
      <Text align="center" variant="displayL">
        Page Not Found
      </Text>
    </Flex>
  )
}
