import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UserSession } from '../../auth'
import { useConfig } from '../../hooks/config'
import { useLoginRedirect } from '../../hooks/navigate'

/**
 * Component for handling auth code exchange for tokens.
 * Redirects on successful auth code exchange.
 */
export function LoginPage(): JSX.Element {
  const navigate = useNavigate()
  const config = useConfig()
  const { handleLoginRedirect } = useLoginRedirect()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const handleLogin = async () => {
      const userSession = new UserSession(config)

      const authCode = searchParams.get('code')
      if (authCode === null) {
        handleLoginRedirect()
      } else {
        try {
          await userSession.authenticate(authCode)
          navigate('/', { replace: true })
        } catch (err) {
          navigate('/error', { replace: true }) // TODO: Handle authentication errors better
        }
      }
    }

    handleLogin()
  }, [searchParams, config, handleLoginRedirect, navigate])

  // Return empty element, since this component redirects on render
  return <></>
}
