import {
  Flex,
  MenuButton,
  Pagination,
  Select,
  Table,
  TableColumn,
  TableColumnSort,
  Tag,
  Text,
  TextInput,
  useToasts,
} from '@applyboard/crystal-ui'
import { useEffect, useMemo, useState } from 'react'
import { ProfileData, UserType, getDisplayLabel } from '../../clients'
import { useAllProfiles, useDeleteProfile } from '../../hooks'
import { LoadingPage } from '../../pages/LoadingPage'
import { hasHigherLevelOfUserType } from '../../utils/auth'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'

type FilterLabel =
  | 'First Name'
  | 'Last Name'
  | 'Email'
  | 'User Type'
  | 'App Access'
const FilterableKeys = ['givenName', 'familyName', 'email', 'userType'] as const
type FilterableKey = (typeof FilterableKeys)[number]

interface OptionProps {
  disabled?: boolean
  label: FilterLabel
  value: keyof ProfileData
}

const PAGE_SIZE = 10

const FILTER_OPTIONS: Array<OptionProps> = [
  {
    label: 'First Name',
    value: 'givenName',
  },
  {
    label: 'Last Name',
    value: 'familyName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'User Type',
    value: 'userType',
  },
  {
    label: 'App Access',
    value: 'apps',
    disabled: true,
  },
]

type UserManagementTableProps = {
  currentSessionUser: ProfileData | undefined
  isGetProfileLoading: boolean
}
export function UserManagementTable({
  currentSessionUser,
  isGetProfileLoading,
}: UserManagementTableProps): JSX.Element {
  const toasts = useToasts()

  const [filter, setFilter] = useState<FilterableKey>('givenName')
  const [search, setSearch] = useState('')

  const [profileToDelete, setProfileToDelete] = useState<
    ProfileData | undefined
  >(undefined)

  const [page, setPage] = useState(0)

  const { allProfileData, isProfilesLoading, refetchAllProfileData } =
    useAllProfiles()

  const currentSessionUserIsResolved =
    !isGetProfileLoading && currentSessionUser

  const { deleteProfile, isDeletingProfile } = useDeleteProfile()

  const [data, setData] = useState<Array<ProfileData> | undefined>(
    allProfileData
  )

  useEffect(() => {
    if (allProfileData) {
      const filteredData = getFilteredData(allProfileData, filter, search)
      setData(filteredData)
    }
  }, [allProfileData, filter, search])

  const isValidFilterKey = (filterKey: string): filterKey is FilterableKey => {
    return FilterableKeys.includes(filterKey as FilterableKey)
  }
  const handleChangeFilter = (newFilter: string) => {
    if (isValidFilterKey(newFilter)) {
      setFilter(newFilter)
    }
  }

  const columns = useMemo<TableColumn<ProfileData>[]>(
    () => [
      {
        accessorKey: 'givenName',
        header: 'First Name',
        enableSorting: true,
      },
      {
        accessorKey: 'familyName',
        header: 'Last Name',
        enableSorting: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
      },
      {
        accessorKey: 'apps',
        header: 'App Access',
        cell: ({ apps }: ProfileData) => {
          const dataApps = apps.map((app) => ({
            key: crypto.randomUUID(),
            app,
          }))

          return (
            <Table.Cell>
              <Flex gap={1}>
                {dataApps?.map(({ key, app }) => (
                  <Tag key={key} intent={'secondary'}>
                    {app}
                  </Tag>
                ))}
              </Flex>
            </Table.Cell>
          )
        },
      },
      {
        accessorKey: 'userType',
        header: 'User Type',
        enableSorting: true,
        cell: ({ userType }: ProfileData) => (
          <Table.Cell>
            <Tag
              intent={
                (
                  {
                    [UserType.owner]: 'negative',
                    [UserType.admin]: 'warning',
                    [UserType.staff]: 'positive',
                  } as const
                )[userType]
              }
            >
              {getDisplayLabel(userType)}
            </Tag>
          </Table.Cell>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: (profile: ProfileData) =>
          currentSessionUserIsResolved ? (
            <Table.ActionsCell>
              <MenuButton.Link
                href={`/profiles/${profile.id}`}
                id="view-profile"
                disabled={
                  !hasHigherLevelOfUserType(
                    currentSessionUser.userType,
                    profile.userType
                  )
                }
              >
                Edit Profile
              </MenuButton.Link>
              <MenuButton.Button
                id="remove-profile"
                onClick={() => setProfileToDelete(profile)}
                disabled={
                  !hasHigherLevelOfUserType(
                    currentSessionUser.userType,
                    profile.userType
                  ) || currentSessionUser.id === profile.id
                }
              >
                Remove Profile
              </MenuButton.Button>
            </Table.ActionsCell>
          ) : (
            <LoadingPage />
          ),
      },
    ],
    [
      currentSessionUser?.id,
      currentSessionUser?.userType,
      currentSessionUserIsResolved,
    ]
  )

  return (
    <>
      <Flex pt={{ xs: 2, sm: 8 }} justify="end" align="center" gap={4}>
        <Text>Filter by:</Text>
        <Select
          aria-label="filter options"
          options={FILTER_OPTIONS}
          onChange={handleChangeFilter}
          value={filter}
          placeholder=""
        />
        <TextInput
          disabled={!isValidFilterKey(filter)}
          helpText=""
          aria-label="search"
          intent="secondary"
          onChange={setSearch}
          placeholder="Search"
          size="md"
          type="search"
          value={search}
        />
      </Flex>
      <Flex pt={{ xs: 2, sm: 8 }} direction="column" justify="center">
        <Table
          loading={isProfilesLoading}
          columns={columns}
          data={
            data?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE) ?? []
          }
          onSort={(newSorting) => {
            if (newSorting.length === 0 && allProfileData) {
              setData(getFilteredData(allProfileData, filter, search))
            } else {
              if (data) {
                const sortedData = data.slice()
                sortedData.sort((a, b) => sortData(newSorting, a, b))
                setData(sortedData)
              }
            }
          }}
        >
          <></>
        </Table>
      </Flex>
      {profileToDelete !== undefined && (
        <ConfirmDeleteDialog
          data={profileToDelete}
          isLoading={isDeletingProfile}
          onClose={() => setProfileToDelete(undefined)}
          onConfirm={(id: string) => {
            deleteProfile(
              { id },
              {
                onSuccess: () => {
                  setProfileToDelete(undefined)
                  refetchAllProfileData()
                  toasts.positive('Success: Removed user.')
                },
                onError: () => {
                  setProfileToDelete(undefined)
                  toasts.negative(new Error('Error: Unable to remove user.'))
                },
              }
            )
          }}
        />
      )}
      <Flex pt={{ xs: 2, sm: 8 }} justify="start" align="center" basis="100%">
        {!isProfilesLoading && (
          <Pagination
            currentPage={page + 1}
            numItems={Math.min(allProfileData?.length ?? 0, data?.length ?? 0)}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={PAGE_SIZE}
          />
        )}
      </Flex>
    </>
  )
}

/** From Crystal UI storybook */
function sortData(sorting: TableColumnSort, a: ProfileData, b: ProfileData) {
  if (!a || !b) {
    return 0
  }
  if (sorting[0].id === 'id') {
    return (sorting[0].desc ? -1 : 1) * a.id.localeCompare(b.id)
  }

  const sortKey1 = sorting[0].id as FilterableKey
  const sortResult1 =
    (sorting[0].desc ? -1 : 1) * a[sortKey1].localeCompare(b[sortKey1])
  if (sortResult1 !== 0 || sorting.length === 1) {
    return sortResult1
  }
  const sortKey2 = sorting[1].id as FilterableKey
  return (sorting[1].desc ? -1 : 1) * a[sortKey2].localeCompare(b[sortKey2])
}

function getFilteredData(
  allProfileData: Array<ProfileData>,
  filter: string,
  search: string
): Array<ProfileData> {
  if (filter !== '' && search !== '') {
    const profileFilter = filter as FilterableKey

    const filteredProfiles = allProfileData.filter((profile) =>
      profile[profileFilter].toLowerCase().includes(search.toLowerCase())
    )

    return filteredProfiles
  }

  return allProfileData
}
