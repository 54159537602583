import { useQuery } from 'react-query'
import { ApiClient, ProfileData } from '../../clients'
import { useConfig } from '../config'

type UseGetProfileProps = {
  id: string | undefined
}

type UseGetProfileHook = {
  isGetProfileLoading: boolean
  isGetProfileError: boolean
  getProfileError: Error | null
  profileData: ProfileData | undefined
}

/**
 * Hook to get any profile data given an id/username
 */
export function useGetProfile({ id }: UseGetProfileProps): UseGetProfileHook {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, error, isError } = useQuery<ProfileData, Error>(
    id ?? '', // to satisfy type check only, this query will not run unless `id` is defined (see `enabled` flag)
    async () => {
      if (id) {
        return await apiClient.getProfileData(id)
      }
      throw new Error(`id not specified`)
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: id !== undefined,
    }
  )

  return {
    isGetProfileLoading: isLoading,
    isGetProfileError: isError,
    getProfileError: error,
    profileData: data,
  }
}
