import { useMutation } from 'react-query'
import { ApiClient, EditProfileProps } from '../../clients'
import { useConfig } from '../config'

export function useEditProfile() {
  const config = useConfig()

  const { isLoading, mutate, error, isError } = useMutation<
    void,
    Error,
    EditProfileProps
  >(async (props: EditProfileProps) => {
    await new ApiClient(config).editProfile(props)
  })

  return {
    isEditingProfile: isLoading,
    isError: isError,
    error: error,
    editProfile: mutate,
  }
}
