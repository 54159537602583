import { Flex, Box, Heading, ButtonLink, Text } from '@applyboard/crystal-ui'

export function ExpiredCodeBlock(): JSX.Element {
  return (
    <>
      <Flex direction="column" pb={8}>
        <Box pb={8}>
          <img alt="ApplyBoard logo" src="/applyboard-logo.svg" width={200} />
        </Box>
        <Heading level={2} variant="titleL" intent="negative">
          Link has expired
        </Heading>
        <Box pt={4} pb={8}>
          <Text intent="primary" contrast="low">
            Contact your administrator to re-send you
          </Text>
          <Text intent="primary" contrast="low">
            a new invite.
          </Text>
        </Box>
        <Flex gap={{ xs: 6, sm: 4 }} direction="column">
          <ButtonLink href={'/'} intent="primary">
            Return to home
          </ButtonLink>
        </Flex>
      </Flex>
    </>
  )
}
