import { useQuery } from 'react-query'
import { ApiClient } from '../../clients'

import { UserSession } from '../../auth'
import { useConfig } from '../config'

/**
 * Hook to get current logged in users profile data.
 */
export function useProfile() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, isError } = useQuery(
    ['profile'],
    async () => {
      const userSession = new UserSession(config)
      const payload = userSession.getAuthTokenPayload()
      const username = payload['cognito:username']

      if (username === undefined) {
        throw new Error(`Payload username undefined.`)
      }

      return await apiClient.getProfileData(username)
    },
    {
      enabled: new UserSession(config).isActiveSession(),
    }
  )

  return {
    profileData: data,
    isGetProfileLoading: isLoading,
    isGetProfileError: isError,
  }
}
