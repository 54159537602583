import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Notice,
  Select,
  Switch,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { App, CreateProfileProps, ProfileData, UserType } from '../../clients'
import { validateEmailFormat } from '../../utils'
import { userTypeToPlatformUserTypeOptions } from './userTypeToPlatformUserTypeOptions'

export type ProfileFormProps = {
  currentSessionUser: ProfileData
  profileDataToBeEdited?: ProfileData
  onSubmit: (profileData: CreateProfileProps) => void
  isLoading: boolean
}

export type AmsAppAccessFieldValues = {
  [App.AMS]: boolean
  amsRole?: string
}

export type AddProfileFieldValues = {
  email: string
  givenName: string
  familyName: string
  userType: string
} & AmsAppAccessFieldValues

export type PlatformUserTypeOption = {
  label: string
  value: UserType
}

const { Form, Field } = createForm<AddProfileFieldValues>()

export function ProfileForm({
  currentSessionUser,
  profileDataToBeEdited,
  onSubmit,
  isLoading,
}: ProfileFormProps): JSX.Element {
  const navigate = useNavigate()

  const initialValues: AddProfileFieldValues = {
    email: profileDataToBeEdited?.email || '',
    givenName: profileDataToBeEdited?.givenName || '',
    familyName: profileDataToBeEdited?.familyName || '',
    userType: profileDataToBeEdited?.userType || '',
    [App.AMS]: profileDataToBeEdited?.apps.includes(App.AMS) || false,
  }

  const isEditingOwnProfile =
    currentSessionUser.id === profileDataToBeEdited?.id
  const canEditUserTypeAndApps = currentSessionUser.userType !== UserType.staff

  return (
    <Form
      onSubmit={(formValue) => {
        onSubmit({
          email: formValue.email,
          givenName: formValue.givenName,
          familyName: formValue.familyName,
          userType: formValue.userType as UserType,
          apps: formValue[App.AMS] ? [App.AMS] : [], // TODO: need to find a more scalable way of representing this data
        })
      }}
      defaultValues={initialValues}
    >
      <Flex
        py={{ xs: 2, sm: 8 }}
        justify="between"
        align="center"
        gap={10}
        grow={1}
      >
        <Flex direction="column" basis="80%">
          <Box pb={8}>
            <Field
              as={TextInput}
              disabled={profileDataToBeEdited !== undefined}
              label="Email"
              name="email"
              required="Email is required."
              validate={(email: string) =>
                validateEmailFormat(email) ? true : 'This email is invalid.'
              }
            />
          </Box>
          <Flex pb={8} gap={10}>
            <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
              <Field
                as={TextInput}
                label="Given Name"
                name="givenName"
                required="Given name is required."
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
              <Field
                as={TextInput}
                label="Family Name"
                name="familyName"
                required="Family name is required."
              />
            </Flex.Item>
          </Flex>
          {canEditUserTypeAndApps && (
            <>
              <RolesAndAccessHeading />
              <RolesAndAccessInfoNotice />
              <Flex grow={1}>
                <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
                  <Field
                    as={Select}
                    name="userType"
                    placeholder="Select"
                    options={userTypeToPlatformUserTypeOptions(
                      currentSessionUser.userType
                    )}
                    label="Platform User Type"
                    required="Enter a platform user type."
                    disabled={isEditingOwnProfile}
                  />
                </Flex.Item>
              </Flex>
              <Flex
                direction="column"
                justify="start"
                align="start"
                gap={4}
                pt={{ xs: 8, sm: 10 }}
              >
                <Heading level={2}>App Access</Heading>
                <FillBox>
                  <Flex
                    justify="start"
                    align="center"
                    grow={1}
                    gap={8}
                    basis="100%"
                    pt={{ xs: 4, sm: 6 }}
                  >
                    <Box>
                      <img
                        alt="Application Management Logo"
                        src="/ams-logo.png"
                        width={56}
                        height={56}
                      />
                    </Box>
                    <Flex.Item basis="100%">
                      <Field
                        as={Switch}
                        label={'Application Management'}
                        name={App.AMS}
                        labelPlacement="left"
                        width="fill"
                        disabled={isEditingOwnProfile}
                      />
                    </Flex.Item>
                  </Flex>
                </FillBox>
              </Flex>
            </>
          )}
          <Box py={{ xs: 8, sm: 10 }}>
            <Divider />
          </Box>
          <Flex justify="end" gap={4}>
            <Button
              type="button"
              onClick={() => {
                navigate('/profiles')
              }}
              intent="primary"
              emphasis="outlined"
            >
              Cancel
            </Button>
            <Button loading={isLoading} type="submit" intent="primary">
              {`${profileDataToBeEdited ? 'Edit' : 'Add'} User`}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Form>
  )
}

function RolesAndAccessInfoNotice(): JSX.Element {
  return (
    <Box pt={6} pb={12}>
      <Notice width="fill">
        <Box pb={2}>
          <Text>
            <b>Admin</b> users can add and remove users, adjust account-wide
            settings, and add additional apps to their account.
          </Text>
        </Box>
        <Box>
          <Text>
            <b>Staff</b> users can only view and update their personal profile
            information and security settings.
          </Text>
        </Box>
      </Notice>
    </Box>
  )
}

function RolesAndAccessHeading(): JSX.Element {
  return (
    <Box>
      <Heading level={2}>User Type & Access</Heading>
    </Box>
  )
}

/** Required to manipulate Switch label spacing */
const FillBox = styled.div({
  width: '50%',
})
