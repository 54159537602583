import { App } from './types'

export type Application = {
  name: App
  title: string
  description: string
  icon: string
  learnMoreLink: string
  appLink: string
}

const APPS: Array<Application> = [
  {
    name: App.AMS,
    title: 'Application Management',
    description: 'An applications management platform',
    icon: '/application-management.svg',
    learnMoreLink: 'https://school.demo.ams.applyboard.dev', // TODO: add tenantful logic to get app link based on schoolId
    appLink: 'https://school.demo.ams.applyboard.dev',
  },
  {
    name: App.TrainHub,
    title: 'TrainHub',
    description: 'An international education industry training ecosystem',
    icon: '/trainhub.svg',
    learnMoreLink: 'https://trainhub.com',
    appLink: 'https://trainhub.com',
  },
  {
    name: App.ApplySolutions,
    title: 'ApplySolutions',
    description:
      'A platform that enables schools to make data driven decisions',
    icon: '/application-management.svg',
    learnMoreLink: 'https://solutions.applyboard.com',
    appLink: 'https://solutions.applyboard.com',
  },
]

export class ApplicationsClient {
  static getApplications(apps: Array<App>): Promise<Array<Application>> {
    return new Promise((resolve, reject) => {
      resolve(APPS.filter((item) => apps.includes(item.name)))
    })
  }

  static getAllApplications(): Promise<Array<Application>> {
    return new Promise((resolve, reject) => {
      resolve(APPS)
    })
  }
}
