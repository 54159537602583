type ExtractParameterViaRegexParams = {
  url: string
  regex: RegExp
}

export function extractParameterViaRegex({
  url,
  regex,
}: ExtractParameterViaRegexParams): string {
  const match = regex.exec(url)
  if (match === null || match[1] === undefined) {
    throw new Error(`Failed to extract any value with regex ${regex} from url.`)
  }
  const parameter = decodeURIComponent(match[1])
  return parameter
}
