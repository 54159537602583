import { Button, Dialog, Flex, Text } from '@applyboard/crystal-ui'
import { ProfileData } from '../../../clients'

type ConfirmDeleteDialogProps = {
  data: ProfileData
  isLoading: boolean
  onClose: () => void
  onConfirm: (id: string) => void
}

export function ConfirmDeleteDialog({
  data,
  isLoading,
  onClose,
  onConfirm,
}: ConfirmDeleteDialogProps) {
  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Heading>Confirm Delete User</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column" align="start" gap={4}>
          <Text variant="bodyL" align="center">
            Are you sure you want to remove the profile for {`${data.email}`} ?
          </Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          intent="negative"
          width="fill"
          type="button"
          onClick={() => onConfirm(data.id)}
          loading={isLoading}
        >
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
