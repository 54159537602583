import { useEffect, useState } from 'react'
import { ConfirmPasswordParams } from '../../clients'
import { OnboardContainer } from '../../components/OnboardContainer'
import { SetPasswordForm } from '../../components/SetPasswordForm'
import { ExpiredCodeBlock } from '../../components/SetPasswordForm/ExpiredCodeBlock'
import { extractParameterViaRegex } from '../../utils/search-parameters/extractParameterViaRegex'
import { useGetPasswordStatus } from '../../hooks'
import { LoadingPage } from '../LoadingPage'
import { SuccessBlock } from '../../components/SetPasswordForm/SuccessBlock'
import { Flex } from '@applyboard/crystal-ui'
import { ErrorBlock } from '../../components/SetPasswordForm/ErrorBlock'

export function SetPasswordPage(): JSX.Element {
  const [confirmPasswordFields, setConfirmPasswordFields] = useState<
    Omit<ConfirmPasswordParams, 'newPassword'>
  >({
    username: '',
    temporaryPassword: '',
  })
  const [isCodeExpired, setIsCodeExpired] = useState(false)

  const {
    isGetPasswordStatusLoading,
    isUserForceChangePassword,
    isGetPasswordStatusError,
  } = useGetPasswordStatus({
    username: confirmPasswordFields.username,
    temporaryPassword: confirmPasswordFields.temporaryPassword,
  })

  useEffect(() => {
    const url = window.location.href

    // TODO: error handling if we fail to parse query strings

    const expiryRegex = /expiry=([^&]+)/g
    const expiry = extractParameterViaRegex({
      url: url,
      regex: expiryRegex,
    })

    const now = new Date()
    const expiryDate = new Date(expiry)
    if (now > expiryDate) {
      setIsCodeExpired(true)
    }

    const usernameRegex = /username=([^&]+)/g
    const username = extractParameterViaRegex({
      url: url,
      regex: usernameRegex,
    })

    const codeRegex = /code=([^&]+)/g
    const code = extractParameterViaRegex({
      url: url,
      regex: codeRegex,
    })

    // TODO: Check expiry and show them a different page if expired.

    setConfirmPasswordFields({
      username: username,
      temporaryPassword: code,
    })
  }, [])

  return (
    <OnboardContainer>
      {isCodeExpired ? (
        <ExpiredCodeBlock />
      ) : isGetPasswordStatusLoading ? (
        <LoadingPage />
      ) : isUserForceChangePassword ? (
        <SetPasswordForm
          username={confirmPasswordFields.username}
          temporaryPassword={confirmPasswordFields.temporaryPassword}
        />
      ) : (
        <Flex gap={4} align="stretch" direction="column" justify="center">
          {isGetPasswordStatusError ? <ErrorBlock /> : <SuccessBlock />}
        </Flex>
      )}
    </OnboardContainer>
  )
}
