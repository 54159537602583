export class LocalStorage {
  static setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value)
  }

  static getItem(key: string): string {
    const item = window.localStorage.getItem(key)
    if (item) {
      return item
    }
    throw new Error('Invalid key. The key does not exist.')
  }

  static removeItem(key: string): void {
    window.localStorage.removeItem(key)
  }

  static clear(): void {
    window.localStorage.clear()
  }
}
