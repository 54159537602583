import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { CrystalProvider } from '@applyboard/crystal-ui'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'
import { LOGIN_REDIRECT_URI } from './constants'
import { ConfigProvider } from './hooks/config'
import { AddProfilePage } from './pages/AddProfilePage'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'
import { EditProfilePage } from './pages/EditProfilePage'
import { LoginPage } from './pages/LoginPage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'
import { SetPasswordPage } from './pages/SetPasswordPage'
import { UserManagementPage } from './pages/UserManagementPage'

const queryClient = new QueryClient()

function App() {
  return (
    <CrystalProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route index element={<DashboardPage />} />
                <Route path="/profiles" element={<UserManagementPage />} />
                <Route path="/profiles/add" element={<AddProfilePage />} />
                <Route path="/profiles/:id" element={<EditProfilePage />} />
              </Route>
              <Route element={<ProtectedRoute isSignupOrLoginRoute />}>
                <Route path={LOGIN_REDIRECT_URI} element={<LoginPage />} />
              </Route>
              <Route path="/confirm" element={<SetPasswordPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </CrystalProvider>
  )
}
export default App
