import { UserType } from '../../clients'

export const USER_TYPE_HIERARCHY: Record<UserType, UserType[]> = {
  [UserType.owner]: [UserType.admin, UserType.staff],
  [UserType.admin]: [UserType.admin, UserType.staff],
  [UserType.staff]: [],
}

/**
 * Check if userTypeOne has higher level of user type than userTypeTwo.
 */
export function hasHigherLevelOfUserType(
  userTypeOne: UserType,
  userTypeTwo: UserType
): boolean {
  return USER_TYPE_HIERARCHY[userTypeOne].includes(userTypeTwo)
}
