import { useQuery } from 'react-query'
import { IsUserForceChangePasswordParams } from '../clients'
import { CognitoClient } from '../clients/CognitoClient'
import { useConfig } from './config'

type UseCheckPasswordStatusHookResponse = {
  isGetPasswordStatusLoading: boolean
  isGetPasswordStatusError: boolean
  getPasswordStatusError: Error | null
  isUserForceChangePassword: boolean | undefined
}

/**
 * Hook to get a profiles password reset status.
 * Returns true if the password needs to be set, false otherwise.
 */
export function useGetPasswordStatus({
  username,
  temporaryPassword,
}: IsUserForceChangePasswordParams): UseCheckPasswordStatusHookResponse {
  const config = useConfig()
  const client = new CognitoClient(config)

  const { isLoading, data, error, isError } = useQuery<boolean, Error>(
    username,
    async () => {
      if (username !== '' && temporaryPassword !== '') {
        return await client.isUserForceChangePassword({
          username,
          temporaryPassword,
        })
      }

      throw new Error('username and/or temporaryPassword not specified')
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: username !== '' && temporaryPassword !== '',
    }
  )

  return {
    isGetPasswordStatusLoading: isLoading,
    isGetPasswordStatusError: isError,
    getPasswordStatusError: error,
    isUserForceChangePassword: data,
  }
}
