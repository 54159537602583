export type OAuth2BaseParameters = {
  authEndpoint: string
  clientId: string
}

export type RefreshRequestParameters = OAuth2BaseParameters & {
  refreshToken: string
  grantType: 'refresh_token'
}

export type AuthorizationCodeRequestParameters = OAuth2BaseParameters & {
  authCode: string
  redirectUri: string
  grantType: 'authorization_code'
}

export type OAuth2BaseResponse = {
  access_token: string
  id_token: string
  expires_in: number
  token_type?: 'Bearer'
}

export type RefreshRequestResponse = OAuth2BaseResponse

export type AuthorizationCodeRequestResponse = OAuth2BaseResponse & {
  refresh_token: string
}

export class AuthClient {
  async refresh(
    params: RefreshRequestParameters
  ): Promise<RefreshRequestResponse> {
    const requestBody = new URLSearchParams({
      grant_type: params.grantType,
      refresh_token: params.refreshToken,
      client_id: params.clientId,
    })

    const fetchResponse = await fetch(params.authEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestBody,
    })

    const response: RefreshRequestResponse = await fetchResponse.json()

    if (fetchResponse.ok) {
      return response
    } else {
      console.debug({ response }, 'Authentication Error')
      throw new Error('Failed to refresh user')
    }
  }

  static async authenticateCode(
    params: AuthorizationCodeRequestParameters
  ): Promise<AuthorizationCodeRequestResponse> {
    const requestBody = new URLSearchParams({
      grant_type: params.grantType,
      code: params.authCode,
      redirect_uri: params.redirectUri,
      client_id: params.clientId,
    })

    const fetchResponse = await fetch(params.authEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestBody,
    })

    const response: AuthorizationCodeRequestResponse =
      await fetchResponse.json()

    if (fetchResponse.ok) {
      return response
    } else {
      console.debug({ response }, 'Authentication Error')
      throw new Error('Failed to authenticate user')
    }
  }
}
