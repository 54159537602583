import { Flex, Heading } from '@applyboard/crystal-ui'

export type PageHeadingProps = {
  title: string
  imageSrc?: string
  imageAlt?: string
}

export function PageHeading({
  title,
  imageSrc,
  imageAlt,
}: PageHeadingProps): JSX.Element {
  return (
    <Flex gap={4} align="center">
      {imageSrc && imageAlt && (
        <img src={imageSrc} width="56px" alt={imageAlt} />
      )}
      <Heading level={1} variant="titleL">
        {title}
      </Heading>
    </Flex>
  )
}
