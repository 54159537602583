import { ButtonLink, Flex, Heading, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'

export function HelpBanner(): JSX.Element {
  return (
    <HelpBannerWrapper>
      <Flex gap={5} hideBelow="sm" justify="between">
        <Flex direction="column" gap={4} justify="center">
          <Heading level={3} variant="headlineM">
            Need any help?
          </Heading>
          <Flex basis="small" direction="column">
            <Text intent="secondary" variant="titleS" contrast="mid">
              Your advisor at ApplyBoard is always there to guide you through
              your journey.
            </Text>
            <Text intent="secondary" variant="titleS" contrast="mid">
              If you feel stuck, reach out!
            </Text>
          </Flex>
          <Flex>
            <ButtonLink
              size="sm"
              href="#" /* TODO: Replace with the actual link */
            >
              Request Assist
            </ButtonLink>
          </Flex>
        </Flex>
        <Flex direction="column" gap={4}>
          <img alt="ApplyBoard Logo" src="/applyboard-logo.svg" />
          <img alt="Help Guy Logo" src="/help-guy.png" />
        </Flex>
      </Flex>
    </HelpBannerWrapper>
  )
}

const HelpBannerWrapper = styled.div(() => ({
  '@media (max-width: 600px)': {
    visibility: 'hidden',
  },
  backgroundColor: '#F5F8FF',
  padding: '32px',
  borderRadius: '8px',
}))
