import { Navigate, Outlet } from 'react-router'

import { UserSession } from '../../auth'
import { useConfig } from '../../hooks/config'
import { useLoginRedirect } from '../../hooks/navigate'

export type ProtectedRouteProps = {
  isSignupOrLoginRoute?: boolean
}

/**
 * This component is responsible for managing user redirects if the user is
 * authenticated.
 */
export function ProtectedRoute({
  isSignupOrLoginRoute,
}: ProtectedRouteProps): JSX.Element {
  const config = useConfig()
  const { handleLoginRedirect } = useLoginRedirect()

  const userSession = new UserSession(config)

  if (userSession.isActiveSession()) {
    if (isSignupOrLoginRoute) {
      return <Navigate to={'/'} />
    }

    return <Outlet />
  }

  if (isSignupOrLoginRoute) {
    return <Outlet />
  }

  handleLoginRedirect()

  return <></>
}
