import { ButtonLink, Divider, Flex } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import { NavBarItems } from './Sidebar'

export type DesktopSideBarProps = {
  open: boolean
  primaryNavBarItems: Array<NavBarItems>
  secondaryNavBarItems?: Array<NavBarItems>
}

export function DesktopSidebar({
  open,
  primaryNavBarItems,
  secondaryNavBarItems,
}: DesktopSideBarProps) {
  return (
    <Flex hideBelow="sm">
      <Flex.Item as="aside" grow={0} shrink={0} basis={64}>
        <Flex hideBelow="sm">
          <DesktopSidebarContainer open={open}>
            <Flex direction="column" grow={1} p={2}>
              {primaryNavBarItems.map((item) => (
                <ButtonLink
                  key={item.id}
                  href={item.href}
                  leadIcon={item.icon}
                  width="fill"
                  intent={item.selected ? 'primary' : 'secondary'}
                  emphasis={item.selected ? 'highlighted' : 'transparent'}
                  aria-label={item.label}
                >
                  {open ? item.label : undefined}
                </ButtonLink>
              ))}
              {secondaryNavBarItems && (
                <>
                  <Divider />
                  {secondaryNavBarItems.map((item) => (
                    <ButtonLink
                      key={item.id}
                      href={item.href}
                      leadIcon={item.icon}
                      width="fill"
                      intent={item.selected ? 'primary' : 'secondary'}
                      emphasis={item.selected ? 'highlighted' : 'transparent'}
                      aria-label={item.label}
                    >
                      {open ? item.label : undefined}
                    </ButtonLink>
                  ))}
                </>
              )}
            </Flex>
          </DesktopSidebarContainer>
        </Flex>
      </Flex.Item>
      {open && createPortal(<SidebarOverlay />, document.body)}
    </Flex>
  )
}

const DesktopSidebarContainer = styled.div<{ open: boolean }>((props) => ({
  display: 'flex',
  borderRight: `1px solid ${props.theme.colors.borderRegular}`,
  zIndex: props.open ? 1720 : 'auto',
  backgroundColor: '#FFF',
  position: 'fixed',
  left: 0,
  top: 64,
  minHeight: 'calc(100vh - 64px)',
  transition: 'width 200ms ease-in-out',
  borderRadius: props.open ? '0 16px 16px 0' : 0,
  overflow: 'hidden',
  width: props.open ? 382 : 64,
  a: {
    paddingInline: props.open ? '11px' : 0,
    '> div': {
      justifyContent: props.open ? 'flex-start' : 'center',
    },
  },
}))

const SidebarOverlay = styled.div(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.colors.iconPrimaryStark,
  opacity: 0.31,
  zIndex: 1710,
}))
