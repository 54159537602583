import { UserType } from './types'

const UserTypeToDisplayLabel: Record<UserType, string> = {
  [UserType.owner]: 'Owner',
  [UserType.admin]: 'Admin',
  [UserType.staff]: 'Staff',
}

export function getDisplayLabel(userType: UserType): string {
  return UserTypeToDisplayLabel[userType]
}
