import { Box, Flex, Text, ButtonLink, Heading } from '@applyboard/crystal-ui'

export function SuccessBlock(): JSX.Element {
  return (
    <>
      <Box pb={1}>
        <img alt="success icon" src="/success-tada-2x.svg" width={80} />
      </Box>
      <Flex direction="column" pb={8}>
        <Heading level={2} variant="titleL">
          Account setup is complete
        </Heading>
        <Box pt={2}>
          <Text intent="primary" contrast="low">
            Congratulations! You successfully set a
          </Text>
          <Text intent="primary" contrast="low">
            new password.
          </Text>
        </Box>
      </Flex>
      <Flex gap={{ xs: 6, sm: 4 }} direction="column">
        <ButtonLink href={'/'} intent="primary">
          Sign In
        </ButtonLink>
      </Flex>
    </>
  )
}
